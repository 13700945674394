@import url("https://stijndv.com/fonts/Eudoxus-Sans.css");
$small: 300px;
$medium: 900px;

.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}

.submit-form {
  max-width: 300px;
  margin: auto;
}

.edit-form {
  max-width: 300px;
  margin: auto;
}

.logo-square {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 10%;
}

.logo-square-lg {
  vertical-align: middle;
  width: 75px;
  height: 75px;
  border-radius: 50%;
}

.vertical-center {
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */
  display: flex;
  align-items: center;
}

.cursor-text {
  cursor: text;
}

.cursor-pointer {
  cursor: pointer !important;
}

.vertical-align-middle {
  vertical-align: middle;
}

.text-theme {
  color: #525f7f;
}

.link {
  color: #525f7f;
}
.link:hover {
  color: #525f7f;
}

.mt-10 {
  margin-top: 2rem;
}

.grow {
  transition: all 0.2s ease-in-out;
}
.grow:hover {
  transform: scale(1.01);
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 60vw;
  height: 1.5em;
  white-space: nowrap;
}

.cut-text-sm {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 60vw;
  height: 1.5em;
  white-space: nowrap;
}

.notify-badge {
  position: absolute;
  right: 20px;
  top: -20px;
  background: red;
  text-align: center;
  border-radius: 3px 3px 3px 3px;
  color: white;
  padding: 5px 10px;
  font-size: 20px;
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  border-radius: 90px;
  outline: none;
}
.pagination > .active > a {
  background-color: #525f7f;
  border-color: #525f7f;
  border-radius: 90px;
  color: #fff;
}
.pagination > li > a {
  margin-left: 5px;
  margin-left: 5px;
  border: 1px solid #e0e4e7;
  border-radius: 90px;
  padding: 6px 14px;
  outline: none;
  cursor: pointer;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #525f7f;
  border-color: #525f7f;
  border-radius: 90px;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #8898aa;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 90px;
}

.background-header {
  background-image: url("./assets/img/svgs/upside-down-wave.svg");
}

.fancy {
  line-height: 0.5;
}
.fancy customBox {
  display: inline-block;
  position: relative;
}
.fancy customBox:before,
.fancy customBox:after {
  content: "";
  position: absolute;
  height: 60%;
  top: 0;

  @media screen and (min-width: $medium) {
    border-bottom: 1px solid rgb(231, 231, 231);
    width: 100%;
  }
}
.fancy customBox:before {
  right: 100%;
}
.fancy customBox:after {
  left: 100%;
}

.custom-search-box-outline {
  @media screen and (min-width: $medium) {
    border: 1px solid rgb(231, 231, 231);
    border-radius: 5px;
    box-shadow: 0 0 3rem rgba(136, 152, 170, 0.175) !important;
  }
}

.mt-15 {
  margin-top: 5rem;
}

.container-upload {
  padding: 25px;
  border: 2px dashed #eeeeee;
  background-color: #fafafa;
  text-align: center;
}

customBox {
  width: 100%;
}

em {
  font-style: italic;
}

body {
  position: relative;
  max-width: 100%;
  overflow-x: hidden;
}

strong {
  font-weight: bold;
}

.sideTab {
  border-bottom: "1px solid";
}

.sideTab:hover {
  background-color: #f1f3f4;
}

.vertical-center-success {
  margin-top: 15vh;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
